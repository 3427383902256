export class Slider {
    private $fpSlider           =   $(".front-page .slider");
    private $productSlider      =   $(".product-gallery .slider");

    public constructor() {
        if (this.$fpSlider.length) {
            this.initFpSlider();
        }

        if (this.$productSlider.length) {
            this.initProdSlider();
        }

    }


    private initFpSlider() {
        this.$fpSlider.on('init', (e, slick) => {
            $((<any>slick).$slides[0]).addClass("animating-slick");
        });

        (<any>this.$fpSlider).slick({
            dots: false,
            infinite: true,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 12000,
            fade: true,
            adaptiveHeight: false,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        autoplaySpeed: 10000
                    }
                }
            ]
        });

        let prev = -1;

        this.$fpSlider.on('beforeChange', (e, slick, curr, next) => {
            prev = curr;
        });

        this.$fpSlider.on('afterChange', (e, slick, current) => {
            $((<any>slick).$slides[prev]).removeClass("animating-slick");
            $((<any>slick).$slides[current]).addClass("animating-slick");
        });
    }

    private initProdSlider() {
        (<any>this.$productSlider).slick({
            lazyLoad: 'progressive',
            dots: false,
            arrows: true,
            infinite: false,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        arrows: false
                    }
                }
            ]
        });

        $(".product-gallery .thumbnails .photo-swap").click((e) => {
            let $img = $(e.currentTarget).find("img");
            let index = parseInt($img.attr('data-index'));
            console.log("switch to index", index);
            (<any>this.$productSlider).slick('slickGoTo', index, true);
        });
    }
}