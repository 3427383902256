export class ApiResponse {
    public readonly success: string = "success";
    public readonly failure: string = "failure";
    public readonly debug: string = "debug";

    private data: any;

    constructor(data?: object|string) {
        if (data) {
            if (typeof data === "string")
                this.data = JSON.parse(data);
            else
                this.data = data;
        }
        else
            this.data = this.getDummyError();
    }

    public getData(): object {
        if (this.data.data)
            return this.data.data;
        return null;
    }

    public getErrors(): object[] {
        if (this.data.errors)
            return this.data.errors;
        return null;
    }

    public isSuccess(): boolean {
        return this.data.response === this.success;
    }

    public getResponse(): string {
        return this.data.response;
    }

    private getDummyError(): object {
        return {
            response: this.failure,
            errors: [
                {
                    errorType: "unknown",
                    errorMessage: "An unknown error occured"
                }
            ]
        };
    }
}