export class Team {
    private $map                =   $(".team-map #map");
    public constructor() {
        if (this.$map.length) {
            this.init();
            $(window).trigger('resize');
        }
    }

    public onResize() {
        let $m = $(".team-map");
        let reset = false;
        if ($(window).innerWidth() > 991) {
            (<any>$)("#map-modal-mobile").modal('hide');
            reset = true;
        }
        else if ($(window).innerWidth() < 500) {
            let max = 499;
            let curr = $(window).innerWidth() - 35;
            let scale = curr / max;
            let rect = $m[0].getBoundingClientRect();
            let offsetLeft = (464 * (1 - scale)) - (rect.width * (1 - scale));
            let dist = (700 * (1 - scale)) - (rect.height * (1 - scale));
            $m.css('transform', 'scale(' + scale.toString() + ')').css('left', -1 * offsetLeft)
                .css("margin-top", dist * -1).css("margin-bottom", (dist - 23) * -1);
            setTimeout(() => {
                this.onResize();
            }, 400);
        }
        else {
            reset = true;
        }

        if (reset) {
            $m.css('transform', 'none').css('left', 'auto').css("margin-top", 0).css("margin-bottom", 23);
        }
    }

    private init() {
        $(window).resize(this.onResize.bind(this));

        let $tooltips = this.$map.find("[data-tooltip-map]");
        $tooltips.each((i, e) => {
            $(e).on('mouseover', ev => {
                let tooltipID = parseInt($(ev.currentTarget).attr("data-tooltip-map"));
                $(".map-tooltip").hide();
                $(".map-image").hide();
                let classes = $(ev.currentTarget).attr("class").split(' ');
                for (let i in classes) {
                    if (classes[i].indexOf('map-') !== -1) {
                        let d = classes[i].split('-');
                        let id = parseInt(d[1]);
                        $("#map-" + id.toString() + "-part").show();
                    }
                }

                if ($(window).innerWidth() > 991) {
                    $("#map-tooltip-" + tooltipID.toString()).show();
                }
                else {
                    let $el = $("#map-tooltip-" + tooltipID.toString()).clone().css('display', 'block').attr('id', 'map-tooltip-mobile-' + tooltipID.toString());
                    $("#map-modal-mobile-body").html("").append($el);
                    $el.find(".map-tooltip-close").on('click', () => {
                        (<any>$)("#map-modal-mobile").modal('hide');
                        $(".map-image").hide();
                    });
                    (<any>$)("#map-modal-mobile").modal('show');
                }

                return;
            });
        });

        $(".map-tooltip > .map-tooltip-close").click(e => {
            $(e.currentTarget).closest(".map-tooltip").hide();
            $(".map-image").hide();
        });
    }
}