export class Services {
    public constructor() {
        $(".service-list-link, .service-list-block").mouseenter((e) => {
            const target = $(e.currentTarget);
            const dataID = $(target).attr('data-service-id');

            const otherClass = $(target).hasClass('service-list-link') ? 'service-list-block' : 'service-list-link';

            const selector = "." + otherClass + "[data-service-id=" + dataID + "]";

            console.log("selector is ", selector);

            $(selector).addClass("hover");

        }).mouseleave(() => {
            $(".service-list-link, .service-list-block").removeClass("hover");
        });
    }
}