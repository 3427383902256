import {ApiResponse} from "./ApiResponse";

export class Form {
    public constructor() {
        $(".ajax-form").each((i, e) => {
            this.initForm($(e));
        });

        if ($(".select2-select").length) {
            (<any>$)(".select2-select").select2({
                minimumResultsForSearch: Infinity
            });
        }
    }

    private initForm(form: JQuery) {
        console.log(form);

        let action = $(form).attr('action');
        let method = $(form).attr('method');
        let formID = $(form).find("input[name=form_id]").val();
        form.on('submit', async (e) => {
            e.preventDefault();
            let fData = $(form).serialize();
            $("#page-busy").css("display", "block");
            let response: ApiResponse = await this.ajaxCall(fData, {
                url: action,
                method: method
            });

            $("#page-busy").css("display", "none");

            if (response.isSuccess()) {
                (<any>$)("#contact-modal-success-" + formID.toString()).modal('show');
                (<any>$)(form)[0].reset();
                (<any>$)(form).find(".select2-select").val("").trigger("change");
                return;
            }

            let errors = response.getErrors();
            let displayedErrors = false;
            for (let i in errors) {
                if (!('errorTarget' in errors[i]))
                    continue;

                displayedErrors = true;
                $(form).find("[name=\"" + (<any>errors[i]).errorTarget + "\"]").addClass("is-invalid").one('focus', (e) => {
                    $(e.currentTarget).removeClass("is-invalid");
                });
            }

            if (!displayedErrors) {
                (<any>$)("#contact-modal-error-" + formID.toString()).modal('show');
            }
        });
    }

    public async ajaxCall(fields: any, options: any = {}): Promise<ApiResponse> {
        return new Promise<ApiResponse>((resolve, reject) => {
            options['dataType'] = 'json';
            options['data'] = fields;
            options['success'] = (data) => {
                resolve(new ApiResponse(data));
            };
            options['error'] = () => {
                resolve(new ApiResponse());
            };
            $.ajax(options);
        });
    }
}