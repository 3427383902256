export class Menu {
    private $nav            =   $("#mainMenuNav");
    private $pageCon        =   $("#page-container");

    private $language       =   $("#language-select");

    private $mainMenu       =   $("#mainMenuNav .menu-main");

    private $subMenu        =   $("#mainMenuNav .menu-sublist");

    public constructor() {
        if (!this.$pageCon.hasClass('front-page')) {
            $(window).on('resize', () => {
                 this.$pageCon.css('padding-top', this.$nav.outerHeight());
            });
            $(window).trigger('resize');
        }


        this.$language.on('change', this.onLangChange.bind(this));

        if (this.$subMenu.length) {
            $(window).on('scroll', this.onScroll.bind(this));
            $(window).on('scroll', this.menuHashTargeting.bind(this));
        }

        $(document).on('click', 'a[href^="#"]', (event) => {
            if ($($(event.currentTarget).attr("href")).length) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $($(event.currentTarget).attr("href")).offset().top
                }, 500);
            }
        });

        $(window).trigger('scroll');

        $("#mainMenuNav > .menu-main > .navbar-toggler").click((e) => {
            $("#mainMenuNav").toggleClass("mobile-visible");
        });
    }

    private onLangChange(e) {
        console.log("Language changed!");
        let opt = this.$language.find(":selected");
        $("#page-busy").show();
        window.location.href = opt.attr("data-url");
    }

    private menuHashTargeting(e) {
        let hashes = this.$subMenu.find('.nav-link[href^="#"]');
        let top = window.pageYOffset;

        if (!hashes.length)
            return;

        let offsets = [];
        for (let i = 0; i < hashes.length; i++) {
            let href = $(hashes[i]).attr('href');
            if (!href.length)
                continue;

            let start = $(href).offset().top - 50;
            let end = start + $(href).outerHeight();

            offsets.push({
                href: href,
                start: start,
                end: end,
                obj: hashes[i],
                fake: this.$subMenu.find(".page-title-submenu[data-item='#" + $(hashes[i]).attr('id') + "']")
            });
        }

        if ($.isEmptyObject(offsets))
            return;

        offsets.sort((a,b) =>  {
            return (a['start'] > b['start']) ? 1 : (a['start'] < b['start']) ? -1 : 0;
        });

        console.log(offsets);

        for (let i = 0; i < offsets.length; i++) {
            let curr = offsets[i];
            if (top <= curr['end']) {
                if (!$(curr['obj']).hasClass('active')) {
                    this.$subMenu.find(".nav-link").removeClass("active");
                    this.$subMenu.find(".page-subtitle-item").removeClass("active");
                    $(curr['obj']).addClass('active');
                    $(curr['fake']).addClass('active');
                }
                return;
            }
            else if (top >= curr['end'] && i === (offsets.length - 1)) {
                if (!$(curr['obj']).hasClass('active')) {
                    this.$subMenu.find('.nav-link').removeClass('active');
                    this.$subMenu.find(".page-subtitle-item").removeClass("active");
                    $(curr['obj']).addClass('active');
                    $(curr['fake']).addClass('active');
                }
                return;
            }
        }
    }

    private lastScroll = null;

    private onScroll(e) {
        if (this.lastScroll === null) {
            this.lastScroll = window.pageYOffset;
            return;
        }

        let curr = window.pageYOffset;
        if (this.lastScroll === curr) {
            return;
        }
        else if (this.lastScroll > curr) {
            this.$mainMenu.css("margin-top", 0);
        }
        else {
            this.$mainMenu.css("margin-top", this.$mainMenu.outerHeight() * -1);
        }

        this.lastScroll = curr;
    }
}