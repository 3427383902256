/// <reference path="./typings/jquery/index.d.ts" />

import {Menu} from './Menu';
import {Slider} from "./Slider";
import {Team} from "./Team";
import {Form} from "./Form";
import {Services} from "./Services";

$(() => {
    console.log("Application ready");
    let menu = new Menu();
    let slider = new Slider();
    let team = new Team();
    let form = new Form();
    let services = new Services();
});